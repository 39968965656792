.form-control {
  border: 1px solid #a1a8c3;
  background-color: #fff;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #c3c7db;
}

.kt-menu__item__object:hover{
  background-color: #c3c7db !important;
}

.input-group-text {
  background-color: #f7f8fa;
  border: 1px solid #a1a8c3;
  /*border: 1px solid #ebedf2;*/
}

.btn.btn-default {
  border: 0;
  background-color: white;
  color: #646c9a;
  border: 1px solid #a1a8c3;
}

.btn.btn-default i {
  color: #536be2;
}

.show > .btn.btn-default, .btn.btn-default.active, .btn.btn-default:active, .btn.btn-default:hover {
  color: #ffffff;
  background: #5d78ff;
  border-color: #5d78ff;
}

.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-datatable__pager-link {
  /*color: #93a2dd;*/
  color: #7680a8;
}

.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-datatable__pager-link.kt-datatable__pager-link--disabled, .kt-datatable > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-datatable__pager-link.kt-datatable__pager-link--disabled:hover {
  /*color: #93a2dd;*/
  color: #7680a8; }

.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .btn.dropdown-toggle {
  /*color: #93a2dd;*/
  color: #7680a8; }

.bootstrap-select > .dropdown-toggle.btn-light, .bootstrap-select > .dropdown-toggle.btn-secondary {
  /*border-color: #ebedf2;*/
  border-color: #a1a8c3;
}

.bootstrap-select .dropdown-menu.inner > li > a span.check-mark {
  /*color: #a1a8c3;*/
  color: #646c9a;
}

.bootstrap-select .dropdown-menu.inner > li.selected > a, .bootstrap-select .dropdown-menu.inner > li:hover > a {
  /*background: #f7f8fa;*/
  background: #c3c7db;
}

.bootstrap-select .dropdown-menu.inner > li.selected > a span.check-mark, .bootstrap-select .dropdown-menu.inner > li:hover > a span.check-mark {
  /*color: #a1a8c3;*/
  color: #646c9a;
}

.bootstrap-select .dropdown-menu.inner > li.selected > a {
  /*background: #f7f8fa;*/
  background: #c3c7db;
}

.kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row,
.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row,
.kt-datatable > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row {
  /*border-bottom: 1px solid #f0f3ff;*/
  border-bottom: 1px solid #c3c7db;
}

.input-group-prepend > .input-group-text {
  border-right: 0px;
}

.input-group-append > .input-group-text {
  border-left: 0px;
}

/*Changed the card-title z-index so it is shown under the scope selector submenu */
.card-title {
  z-index: 10 !important;
}

/* Remove the browser autofill background color on inputs from default to transparent */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px #f8f8f8 inset !important; /* set background color */
  -webkit-text-fill-color: #495057 !important; /* set color */
}


/*Resizable content*/
.resizable{
  position: absolute;
  height: 3px;
  bottom: 0;
  width: 100%;
  cursor: s-resize;
}

.kt-menu__item_equal{
  width: 33.3333% !important;
  height: 100%;
}
.kt-menu__content{
  height: 100%;
}
.kt-menu__item_info{
  width: 66.6666% !important;
}

#scope-selector-rentalObject >ul {
  border-right: 1px solid #ebedf2;
}
#scope-selector-rentalObject >ul, #scope-selector-property >ul {
  height: 300px;
}
#scope-selector-rentalObject > ul, #scope-selector-property > ul, #scope-selector-info > .kt-menu__inner{
  overflow-y: auto;
}
#scope-selector-info > .kt-menu__inner {
  width: 100%;
  height: 300px;
  min-height: 300px !important;
  max-height: 600px;
}
#scope-selector-property, #scope-selector-rentalObject {
  height: 100% !important;
}

#scope-selector-info > .top_, #scope-selector-rentalObject > .top_{
  height: 40px !important;
}

/*user badge design in mobile version*/
.kt-badge.kt-badge--extra {
  height: 60px;
  width: 60px;
  font-size: 3.25rem; }

.width_100{
  width: 100% !important;
}

.height_100{
  height: 100% !important;
}
.height-50{
  height: 50px;
}
.middle{
  height: 50px;
  line-height: 25px !important;
}

.noRentalObject{
  height: 193px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Shorting text and adding dots for long names of properties and rental objects*/
.overflow_text{
  overflow: hidden;
  text-overflow: ellipsis !important;
  height: 20px;
  align-items: flex-start;
  display: inline-block !important;
  width: 130px;
  white-space: nowrap !important;
}
.overflow_prop--mobile{
  overflow: hidden;
  text-overflow: ellipsis !important;
  align-items: flex-start;
  width: 85%;
  display: inline-block !important;
  white-space: nowrap !important;
}
.overflow_text--mobile{
  overflow: hidden;
  text-overflow: ellipsis !important;
  align-items: flex-start;
  display: inline-block !important;
  width: 85%;
  white-space: nowrap !important;
}
.overflow_title{
  overflow: hidden;
  text-overflow: ellipsis !important;
  height: 50px;
  align-items: flex-start;
  display: block !important;
  white-space: nowrap !important;
}

/*remove padding and color from the default dropdown-item*/
.dropdown-item--inner {
  display: block;
  width: 100%;
  /* padding: 0.25rem 1.5rem; */
  clear: both;
  font-weight: 400;
  /* color: #212529; */
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

/*Overlay div style when clicking the search  button in mobile*/
.search-overlay__top {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.1);
  -webkit-animation: kt-animate-fade-in .3s linear 1;
  animation: kt-animate-fade-in .3s linear 1; }
.search-overlay__bottom{
  position: fixed;
  top: 0;
  left: 0;
  height:50px;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.1);
  -webkit-animation: kt-animate-fade-in .3s linear 1;
  animation: kt-animate-fade-in .3s linear 1;
}

@media (min-width: 1025px) {
  .kt-visible-tablet-and-mobile--inline{
    visibility: hidden;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--rel {
  }

  .kt-menu__content {
    background-color: #ffffff;
    /*box-shadow:*/
    height: 100%;
  }

  .kt-menu__content > .kt-menu__item:first-child{
    background-color: #f8f9fa;
  }

  .menu-padding{
    padding: 10px 20px !important;
  }

  .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--active-tab .kt-menu__submenu, .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--hover .kt-menu__submenu {
    display: block;
    background-color: #ffffff;
    -webkit-animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-down 0.3s ease-out 1;
    animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-down 0.3s ease-out 1;
  }
  .width_100{
    width: 100% !important;
  }
  .box-shadow{
    box-shadow: 0 4px 10px -2px !important;
  }

  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link:hover{
    background-color: #e1e1ef !important;
  }

  .kt-menu__inner > .kt-menu__item__feature:hover{
    background-color: #e1e1ef !important;
  }

  .kt-menu__inner > .kt-menu__item__object:hover{
    font-style: unset;
  }

  .kt-menu__item__object > .sub_object{
    display: block !important;
  }

  .kt-menu__inner > .kt-menu__item__feature:hover{
    color: #415DE8;
  }
}

@media (max-width: 1024px){
  .kt-visible-tablet-and-mobile--inline{
    display: inline-block !important;
  }

  .focus_selector{
    height: 100%;
    font-weight: 400;
    font-size: 1rem;
    top: 0;
    bottom: 0;
  }

  .nav-link.dropdown-toggle:after, .btn.dropdown-toggle:after{
    display: none;
  }

  .kt-header-second-menu-wrapper-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.1);
    -webkit-animation: kt-animate-fade-in .3s linear 1;
    animation: kt-animate-fade-in .3s linear 1;
  }
  .kt-header-second-menu-wrapper-overlay {
    background: rgba(0, 0, 0, 0.1);
  }

  /*    .fucus_selector_a{
          height: 100%;
      }*/
  .dropdown-menu-xl{
    width: 100% !important;
    right: 0 !important;
    transform: translate3d(0px, 50px, 0px) !important;
  }

  .dropdown-menu > ul > li, .dropdown-menu > .dropdown-item {
    outline: none !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    flex-direction: column;
    padding: 0.7rem 1.2rem;
    color: #646c9a;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .dropdown-menu.dropdown-menu-xl{
    position: fixed !important;
    top:60px !important;
  }

  #search_input{
    height:50px;
    border: none;
    outline: none;
  }
  .kt-header__topbar-icon{
    top:0;
    bottom: 0;
  }

  .nav-link {
    display: block;
    padding: 1rem 1rem !important;
  }

  .padding_left_15{
    padding-left: 15px;
  }
  .kt-visible-mobile_{
    display: inline !important;
  }
  .mobile_margin{
    margin-top: 50px;
  }

  .kt-header__topbar--mobile-on .kt-header__topbar{
    border-top: none !important;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler {
    margin: 0 0 0 0;
  }
  .kt-header__topbar .kt-header__topbar-item {
    margin: 0px 0px;
    padding: 0 !important;
  }

  .kt-header__topbar{
    padding: 0px 0px !important;

  }
  .kt-header__topbar-item{
    height: 100%;
  }

  .search_space{
    position: absolute;
    float: left;
    bottom: 0;
    top: 0;
    right: 0;
    left: auto;
    float:right;
    padding: 0px 10px !important;
    border-left: 1px solid #c3c7db;
    /*     -webkit-animation: slide 2s ease;
         -webkit-animation-delay: 2s;
         animation: slide 2s ease;
         animation-delay: 2s;*/
  }
  @-webkit-keyframes slide {
    100% { left: 0;
      right: auto;
      border-right: 1px solid #c3c7db;
      border-left: none; }
  }

  @keyframes slide {
    100% { left: 0;
      right: auto;
      border-right: 1px solid #c3c7db;
      border-left: none;  }
  }

  .search_b {
    pointer-events: none;
    margin: 0px -100% 0px 100%;
    left: 0;
    border: none;
    width: 100%;
    height: 100% !important;
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
  }

  .search_b input {
    margin: 0px 50px 0px 0px;
    width: 0%;
    left: 0;
    float: left;
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
    height: 100%;
    padding: 0 10px 0 10px;
    border-radius:1px;
    border: none;
  }

  .search_b .btn_b:hover {
    width: 100%;
    margin: 0px 0px 0px 0px;
  }

  .btn_b:hover{
    width: 50px !important;
    left: 0;
    right: auto;
    border-right: 1px solid #c3c7db;
    border-left: none;
    margin: 0px 0px 0px 0px;
  }

  .btn_b:hover input, .search input:focus {
    width: 100%;
    margin: 0px 0px 0px 0px;
  }

  .btn_b {
    margin: 0px 0px 0px -50px;
    width: 50px;
    height: 100%;
    top:0;
    bottom: 0;
    border-top: none;
    border-bottom: none;
    border-right: none;
    border-left: 1px solid #c3c7db;
    background-color: transparent;
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
  }

  .kt-header__topbar-item{
    margin: 0;
    padding: 0;
  }

}


/* ---------------------------------------- */
/* Map Customize */
/* --------------------------------------- */

.gm-style .controls {
  font-size: 28px;  /* this adjusts the size of all the controls */

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 300;
  height: 1em;
  margin: 6px;
  text-align: center;
  user-select: none;
  padding: 2px;
  width: 1em;
}
.gm-style .controls button {
  border: 0;
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
}
.gm-style .controls button:hover {
  color: rgba(0, 0, 0, 0.9);
}
.gm-style .controls.zoom-control {
  display: flex;
  flex-direction: column;
  height: auto;
}
.gm-style .controls.zoom-control button {
  font: 0.85em Arial;
  margin: 1px;
  padding: 0;
}
.gm-style .controls.maptype-control {
  display: flex;
  flex-direction: row;
  width: auto;
}
.gm-style .controls.maptype-control button {
  display: inline-block;
  font-size: 0.5em;
  margin: 0 1px;
  padding: 0 6px;
}
.gm-style .controls.maptype-control.maptype-control-is-map .maptype-control-map {
  font-weight: 700;
}
.gm-style .controls.maptype-control.maptype-control-is-satellite .maptype-control-satellite {
  font-weight: 700;
}
.gm-style .controls.fullscreen-control button {
  display: block;
  font-size: 1em;
  height: 100%;
  width: 100%
}
.gm-style .controls.fullscreen-control .fullscreen-control-icon {
  border-style: solid;
  height: 0.25em;
  position:absolute;
  width: 0.25em;
}
.gm-style .controls.fullscreen-control .fullscreen-control-icon.fullscreen-control-top-left {
  border-width: 2px 0 0 2px;
  left: 0.1em;
  top: 0.1em;
}
.gm-style .controls.fullscreen-control.is-fullscreen .fullscreen-control-icon.fullscreen-control-top-left {
  border-width: 0 2px 2px 0;
}
.gm-style .controls.fullscreen-control .fullscreen-control-icon.fullscreen-control-top-right {
  border-width: 2px 2px 0 0;
  right: 0.1em;
  top: 0.1em;
}
.gm-style .controls.fullscreen-control.is-fullscreen .fullscreen-control-icon.fullscreen-control-top-right {
  border-width: 0 0 2px 2px;
}
.gm-style .controls.fullscreen-control .fullscreen-control-icon.fullscreen-control-bottom-left {
  border-width: 0 0 2px 2px;
  left: 0.1em;
  bottom: 0.1em;
}
.gm-style .controls.fullscreen-control.is-fullscreen .fullscreen-control-icon.fullscreen-control-bottom-left {
  border-width: 2px 2px 0 0;
}
.gm-style .controls.fullscreen-control .fullscreen-control-icon.fullscreen-control-bottom-right {
  border-width: 0 2px 2px 0;
  right: 0.1em;
  bottom: 0.1em;
}
.gm-style .controls.fullscreen-control.is-fullscreen .fullscreen-control-icon.fullscreen-control-bottom-right {
  border-width: 2px 0 0 2px;
}

/* Brute force fix for backdrop overlay problem in Edit Creditor Invoices (Reciept selection)
   @see: https://weblog.west-wind.com/posts/2016/sep/14/bootstrap-modal-dialog-showing-under-modal-background
*/

.modal-backdrop { display: none; }
.modal { background: rgba(0,0,0,0.5); }

.subtitle {
  font-weight: 600;
  color: #74788d;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.room-list {
  fieldset {
    display: inline-block;
  }
  .room-list-item {
    width: calc(100% - 10px) !important;

    .room-list-item--name {
      margin-top: 30px;
    }
  }
}

.asset-conditions {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
}

.asset-condition {
  width: 150px;
  height: 150px;
  background-color: #DDD;
  padding: 15px;
  margin: 10px;
  cursor: pointer !important;
}

.asset-condition:hover {
  opacity: 0.8;
}
.card-row {
  background-color: #F9F9FC;
  padding: 10px;
  margin-bottom: 5px;
}
.kt-portlet--solid-info {
  background-color: #68759c !important;
}

.card-columns .card .card-text > * {
  column-count: 1;
}
.asset-list {
  fieldset {
    display: inline-block;
  }
  .asset-list-item {
    width: calc(100% - 10px) !important;
    min-height: auto !important;

    .asset-list-item--name {
      margin-top: 0px;
    }
  }
}

.kt-offcanvas-panel.kt-offcanvas-panel--on {
  width: 35%!important;
}

.asset-modal .modal-dialog {
  max-width: 1200px;
}


.pointer {
  cursor: pointer;
}
//table {
//  tbody td {
//    vertical-align: middle !important;
//  }
//}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: unset !important;
}
.kt-login-v2 .kt-login-v2__body .kt-login-v2__body-wrapper .kt-login-v2__body-container .kt-login-v2__body-form.kt-login-v2__body-form--border .form-group .form-control {
  padding-left: 1rem;
}
.btn-list {
  button {
    margin: 0 2px;
  }
}
.grid-container {
  height: 300px;
  width: 100%;
}
.gm-style .gm-style-iw-c {
  border-radius: 0;
}
#property-list-map {
  position: relative;
  width: 100%;
  height: 100%;
  #property-list-map--map {
    position: relative;
    width: 100%;
    height: 100%;

    .property-list-map--infowindow {
      width: 500px;
      height: 150px;
      overflow: hidden;

      h1 {
        font-size: 1.5em;
      }

      .row {
        height: 100%;
      }

      .row > * {
        background-size: cover;
      }

      .btn {
        color: #FFF !important;
      }

    }
  }
}
.card-columns .card .card-text > * {
  column-count: 1;
}

.card-title {
  position: relative;
  z-index: 1000;
}

.table-no-padding td {
  padding: 0.75rem 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.kt-aside-secondary--expanded .kt-aside-secondary .kt-aside-secondary__content {
  right: 0 !important;
}
.kt-aside-secondary--expanded .kt-aside-secondary .kt-aside-secondary__content {
  width: 600px;
}

#scope-selector-property > ul, #scope-selector-rentalObject > ul {
  height: 300px;
  min-height: 300px !important;
}

#scope-selector-property, #scope-selector-rentalObject {
  height: 100% !important;
}

.hidden {
  display: none;
}

/* Accounting */
.accounting {
  margin-bottom: 30px;

  .accounting-group {
    border-bottom: 1px solid #CCC;
    padding-bottom: 7px;
  }
  .accounting-account {
    font-size: 14px;
    &:hover {
      background-color: #EFEFEF;
    }
  }
  .accounting-position {
    min-width: 50px;
    max-width: 100px;
    display: inline-block;
  }
}

.document-viewer-container iframe {
  border: 0;
  height: 100%;
  width: 100%;
}

.document-viewer-container {
  padding: 0 !important;
}

.document-viewer-container img.document-viewer {
  width: 100%;
  object-fit: cover;
}


.kt-media {
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 4px; }
.kt-media img {
  width: 100%;
  max-width: 50px;
  height: 50px; }
.kt-media span {
  width: 50px;
  height: 50px;
  font-size: 1.3rem; }
.kt-media.kt-media--fixed {
  width: 50px;
  height: 50px; }
.kt-media.kt-media--fixed img {
  width: 50px;
  height: 50px; }
.kt-media img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px; }
.kt-media span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 600;
  color: #74788d;
  border-radius: 4px; }
.kt-media.kt-media--default {
  background-color: #ebedf2; }
.kt-media.kt-media--brand span {
  background: rgba(93, 120, 255, 0.1);
  color: #5d78ff; }
.kt-media.kt-media--metal span {
  background: rgba(211, 218, 230, 0.1);
  color: #d3dae6; }
.kt-media.kt-media--light span {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff; }
.kt-media.kt-media--dark span {
  background: rgba(100, 92, 161, 0.1);
  color: #645ca1; }
.kt-media.kt-media--accent span {
  background: rgba(0, 197, 220, 0.1);
  color: #00c5dc; }
.kt-media.kt-media--focus span {
  background: rgba(152, 22, 244, 0.1);
  color: #9816f4; }
.kt-media.kt-media--primary span {
  background: rgba(88, 103, 221, 0.1);
  color: #415DE8; }
.kt-media.kt-media--success span {
  background: rgba(29, 201, 183, 0.1);
  color: #1dc9b7; }
.kt-media.kt-media--info span {
  background: rgba(85, 120, 235, 0.1);
  color: #5578eb; }
.kt-media.kt-media--warning span {
  background: rgba(255, 184, 34, 0.1);
  color: #ffb822; }
.kt-media.kt-media--danger span {
  background: rgba(253, 57, 122, 0.1);
  color: #fd397a; }
.kt-media.kt-media--xs img {
  width: 100%;
  max-width: 24px;
  height: 24px; }
.kt-media.kt-media--xs span {
  width: 24px;
  height: 24px;
  font-size: 0.8rem; }
.kt-media.kt-media--xs.kt-media--fixed {
  width: 24px;
  height: 24px; }
.kt-media.kt-media--xs.kt-media--fixed img {
  width: 24px;
  height: 24px; }
.kt-media.kt-media--sm img {
  width: 100%;
  max-width: 30px;
  height: 30px; }
.kt-media.kt-media--sm span {
  width: 30px;
  height: 30px;
  font-size: 0.9rem; }
.kt-media.kt-media--sm.kt-media--fixed {
  width: 30px;
  height: 30px; }
.kt-media.kt-media--sm.kt-media--fixed img {
  width: 30px;
  height: 30px;}
.kt-media.kt-media--md img {
  width: 100%;
  max-width: 47px;
  height: 47px; }
.kt-media.kt-media--md span {
  width: 47px;
  height: 47px;
  font-size: 1rem; }
.kt-media.kt-media--md.kt-media--fixed {
  width: 47px;
  height: 47px; }
.kt-media.kt-media--md.kt-media--fixed img {
  width: 47px;
  height: 47px; }
.kt-media.kt-media--lg img {
  width: 100%;
  max-width: 65px;
  height: 65px; }
.kt-media.kt-media--lg span {
  width: 65px;
  height: 65px;
  font-size: 1.3rem; }
.kt-media.kt-media--lg.kt-media--fixed {
  width: 65px;
  height: 65px; }
.kt-media.kt-media--lg.kt-media--fixed img {
  width: 65px;
  height: 65px; }
.kt-media.kt-media--xl img {
  width: 100%;
  max-width: 80px;
  height: 80px; }
.kt-media.kt-media--xl span {
  width: 80px;
  height: 80px;
  font-size: 1.7rem; }
.kt-media.kt-media--xl.kt-media--fixed {
  width: 80px;
  height: 80px; }
.kt-media.kt-media--xl.kt-media--fixed img {
  width: 80px;
  height: 80px; }
.kt-media.kt-media--circle {
  border-radius: 50%; }
.kt-media.kt-media--circle img {
  border-radius: 50%; }
.kt-media.kt-media--circle span {
  border-radius: 50%; }


/* table */
th.kt-datatable__cell--sorted > span { color: #000 !important; font-weight: 600 !important; }
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body .kt-datatable__row > .kt-datatable__cell > span {
  color: #000 !important;
}
td .btn-hover-brand { background: #f9f9f9 !important; color: #5d78ff !important; border-radius: 3px !important; margin: 0 2px !important; }
