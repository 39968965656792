
#header {
  background-color: #4f6769;    height: 300px;
  width: 100%;
  background: #4f6769;
  position: absolute;

  h2 {
    width: 50%;
    margin: 0 auto;
    color: white;
    padding-top: 30px
  }

}
.fwc-listing {
  max-width: 70%;
  min-width: 50%;
  margin: 40px auto 50px;
  color: #444;

  &.fwc-listing--bg {

    background-color: white;
    z-index: 500;
    padding: 50px;
    margin-top: 100px;
  }

  #map {
    width: 100%;
    height: 100%;
  }

  .fwc-gallery {
    margin-bottom: 30px;
    margin-top: 20px;

    div > img {
      width: 100%;
    }
  }

  .fwc-listing-facts {
    display: flex;
    justify-content: space-between;
  }
  .fwc-fact:not(.fwc-fact--unit) {
    font-size: 24px;
    font-weight: 600;
  }

  .fwc-fact--unit {
    font-size: 16px;
  }

  h1 {
    font-weight: 600;
  }

  .row {
    .col {

      ul {
        margin-top: 30px;

        li:first-child {
          border-top: 1px solid #999;

        }
        li {
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 42px;
          letter-spacing: -0.01em;
          text-transform: uppercase;
          border-bottom: 1px solid #999;

          i:first-child:not(.yes):not(.no) {
            margin-right: 25px;
            margin-left: 20px;
          }

          span {
            padding-right: 10px;
          }

          i.yes {
            float: right;
            line-height: 42px;
            color: #439943;
            margin-right: 10px;
          }

          i.no {
            float: right;
            line-height: 42px;
            color: #AF0000;
            margin-right: 10px;
          }
        }
      }
    }
    .col:nth-child(1) {
      h3 {
        font-size: 22px;
        margin-top: 20px;
      }

    }
    .col.bg {
      background-color: #ddd;
      box-shadow: 0px 16px 48px 0px rgba(17, 29, 35, 0.15)

    }
    .col:nth-child(2) {
      h2, p {
        font-size: 16px;
        line-height: 28px;
      }

      h2 {
        font-weight: 600;
      }

      p {
      }
    }
  }
}
