.table-wrapper-styles {
  position: relative;
  font-size: 11px;
}

.table-margin {
  margin-left: -1px;
}

.tableFixHead {
  overflow: auto;
  height: 80vh;
}

.tableFixHead thead th {
  position: sticky;
  vertical-align: middle;
  border-top: none;
  top: 0;
}

.tableFixHead table  {
  border-collapse: collapse;
  width: 100%;
  border-top: none;
}

.tableFixHead th td {
  padding: 8px 16px;
}

.tableFixHead th {
  background: white;
}

.actions {
  white-space: nowrap;
}

.actions > a {
  background: transparent !important;
}

.form-wrapper {
  padding: 2rem !important;
}

.form-wrapper > form > .form-group {
  margin-bottom: 1rem !important;
}

#administration_panel .datepicker {
  z-index: 10000 !important;
}