//
// Wizard v2
//




// Custom pages SASS files are compiled per demo as
// each demo has own config settings that may override the global theme config
// and pages may slighty different on demos


// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";


$kt-wizard-v2-step-bg: #d2d0e0;
$kt-wizard-v2-step-number: #9896a6;
$kt-wizard-v2-font: #918ea7;

// General mode
.kt-wizard-v2 {
	// Nav
	.kt-wizard-v2__nav {
		padding: 4rem 3rem;

		@include kt-minimal-desktop-and-below{
			padding: 2rem 1rem;
		}

		@include kt-mobile-sm{
			padding: 2rem 0;
		}

		.kt-wizard-v2__nav-items{
			display:flex;
			flex-direction: column;

			.kt-wizard-v2__nav-item{
				display: flex;
				flex-direction: row;
				align-items: center;
				flex-wrap: nowrap;
				flex-basis: auto;
				margin-bottom: 2.3rem;
				font-weight: 400;
				font-size:1.15rem;

				span {
					width: 2.7rem;
					height: 2.7rem;
					font-size: 1.2rem;
					border-radius: 50%;
					display:flex;
					align-items: center;
					justify-content: center;
					margin: 0 1rem;
				}

				i{
					display: none;
					width: 2.7rem;
					height: 2.7rem;
					font-size: 1rem;
					border-radius: 50%;
					align-items: center;
					justify-content: center;
					margin: 0 1rem;
				}

				&[data-ktwizard-state="current"]{
					color: kt-state-color(brand, base);

					span{
						background-color: kt-state-color(brand, base);
						color: kt-state-color(brand, inverse);
					}
				}

				&[data-ktwizard-state="pending"]{
					color: $kt-wizard-v2-font;

					span{
						background-color: $kt-wizard-v2-step-bg;
						color: kt-state-color(brand, inverse);
					}
				}

				&[data-ktwizard-state="done"]{
					color: kt-state-color(success, base);

					span {
						display: none;
					}

					i {
						display:flex;
						background-color: kt-state-color(success, base);
						color: kt-state-color(success, inverse);
					}
				}
			}

			&.kt-wizard-v2__nav-items--clickable {
				.kt-wizard-v2__nav-item {
					cursor: pointer;
				}
			}
		}

		.kt-wizard-v2__nav-details {
			text-align:center;

			.kt-wizard-v2__nav-item-title{
				font-size: 1.5rem;
				font-weight: 400;
				margin-bottom: 2rem;
			}

			.kt-wizard-v2__nav-item-desc{
				font-weight: 400;
				font-size: 1.1rem;
				line-height: 1.8rem;
				color: $kt-wizard-v2-font;
			}
		}
	}

	.kt-form {
		padding: 2rem 4rem 4rem;

		@include kt-mobile-sm{
			padding: 2rem 1rem 4rem;
		}

		.kt-wizard-v2__content{
			padding-bottom: 1.3rem;
			border-bottom: 1px solid #eeeef4;
			margin-bottom: 2rem;
		}

		.kt-form__actions{
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			@include kt-mobile-sm{
				flex-wrap: wrap;
				justify-content: center;
			}

			.btn{
				@include kt-mobile-sm{
					margin: 0 0.5rem 1rem;
				}
			}

			[data-ktwizard-type="action-prev"]{
				margin-right:auto;

				@include kt-mobile-sm{
					margin-right:0.5rem;
				}
			}

			[data-ktwizard-type="action-next"]{
				margin: auto 0 auto auto;

				@include kt-mobile-sm{
					margin: 0 0 1rem;
				}
			}
		}
	}

	// Step
	[data-ktwizard-type="step"] {

		&[data-ktwizard-state="current"] {

		}

		&[data-ktwizard-state="done"] {

		}

		&[data-ktwizard-state="pending"] {

		}
	}

	// Step info
	[data-ktwizard-type="step-info"] {
		display: none;

		&[data-ktwizard-state="current"] {
			display: block;
		}
	}

	// Content states
	[data-ktwizard-type="step-content"] {
		display: none;

		&[data-ktwizard-state="current"] {
			display: block;
		}
	}


 	// Action buttons states
	& {
		[data-ktwizard-type="action-prev"]{
			display: none;
		}

		[data-ktwizard-type="action-next"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-submit"]{
			display: none;
		}
	}

	&[data-ktwizard-state="first"] {
		[data-ktwizard-type="action-prev"]{
			display: none;
		}

		[data-ktwizard-type="action-next"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-submit"]{
			display: none;
		}
	}

	&[data-ktwizard-state="between"] {
		[data-ktwizard-type="action-prev"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-next"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-submit"]{
			display: none;
		}
	}

	&[data-ktwizard-state="last"] {
		[data-ktwizard-type="action-prev"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-next"]{
			display: none;
		}

		[data-ktwizard-type="action-submit"]{
			display: inline-block;
		}
	}
}

// Responsive mode(ref $kt-media-breakpoints in framework\_config.scss)
// Above 1200px
@include kt-media-above(xl) {
	.kt-wizard-v2 {
		.kt-wizard-v2__aside {
			width: 400px;
			border-right: 1px solid #eeeef4;
			padding: 1.5rem;
		}

		.kt-wizard-v2__wrapper {
			padding: 1.5rem;
		}
	}
}

// Above 1024px and below 1200px
@include kt-media-range(lg, xl) {
	.kt-wizard-v2 {
		.kt-wizard-v2__aside {
			border-bottom: 1px solid #eeeef4;
			padding: 1rem;
		}

		.kt-wizard-v2__wrapper {
			padding: 1rem;
		}
	}
}

// Below 1024px
@include kt-media-below(lg) {
	.kt-wizard-v2 {
		.kt-wizard-v2__aside {
			padding: 1rem;
			border-bottom: 1px solid #eeeef4;
		}

		.kt-wizard-v2__wrapper {
			padding: 1rem;
		}
	}
}
