// base style
@import "../theme/src/assets/sass/style";

@import "../sass/fwc-colors";

// skins for demo1 only
@import "../theme/src/assets/sass/global/layout/aside/skins/navy";
@import "../theme/src/assets/sass/global/layout/brand/skins/navy";
@import "../theme/src/assets/sass/global/layout/header/skins/base/light";
@import "../theme/src/assets/sass/global/layout/header/skins/menu/light";

@import "../theme/src/assets/sass/pages/wizards/wizard-v2";

@import "brikena";

// Required Core Stylesheet
@import "~@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
@import "~@glidejs/glide/src/assets/sass/glide.theme";


// Put hubspot chat bubble under modals
#hubspot-messages-iframe-container {
  z-index: 1000 !important
}

.fw-toggle-button {
    font-size: 1.2rem;
    display: block;
    > .la {
        transition: transform ease-in-out .3s;
    }
    &[aria-expanded="true"] > .la {
        transform: rotate(90deg);
    }
}

body:not(.fw-app--new-content) #kt_content_body {
  width: 100%;
  padding-top: 26px;
}

.clear-fix {
  @extend .clearfix;
}

.card-columns-flex {
  @media (min-width: 576px) {
    display: flex;
    gap: 1.25rem;
    .cards-column {
      width: 33.3333%;
    }
  }

  .card {
    margin-bottom: .75rem;
  }
}

.glide__slides {
  @extend .align-items-center;
  text-align: center;
  .glide__slide {
    img {
      max-height: 500px;
      width: 100%;
      object-fit: contain;
    }
  }
}

.glide__arrow {
  background-color: black;
}

.glide__bullet {
  border-color: black;
  &:hover {
    border-color: white;
    background-color: black;
  }
  &--active {
    border-color: black !important;
    background-color: black !important;
  }
}

form label.required:before {
  color: #E53935;
  content: " * ";
}

.dropdown-header {
  font-weight: bold;
}

.dropdown-menu {
  max-height: 90vh;
}

.full-width {
  width: 100%;
}

@media (max-width: 576px) {
  .kt-offcanvas-panel.kt-offcanvas-panel--on {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 1024px) {
  .kt-offcanvas-panel.kt-offcanvas-panel--on {
    width: 50% !important;
  }
}

.kt-aside-secondary__content {
  overflow-y: scroll;
  .dropdown-menu.show {
    transform: translate3d(0px, 0px, 0px) !important;
    min-width: 550px !important;
    width: 550px !important;
  }
}

fieldset {
  margin-bottom: 0 !important;
}

.tt-hint {
  display: none;
}

.kt-form__actions {
  margin-top: 20px;
}

.damage-manager {
  .kt-switch {
    margin-left: auto;
    margin-bottom: -15px;
    margin-right: 15px;
  }
  .kt-grid-nav-v2__item-title > select {
    margin-top: 15px;
  }
}

.kt-grid-nav-v2__item.done {
  background-color: $fwc-green;
}

.kt-aside-secondary--expanded .kt-aside-secondary .kt-aside-secondary__content {
  z-index: 10000 !important;
}

.bootstrap-select.bs-container .dropdown-menu {
    z-index: 10000001;
}

// Detail View Header Styles (temporarily located here, will be moved and routed shortly)

.fwc-detail-header {

  .kt-profile__main-pic {
    position: relative;
    i {
      font-size: 36px;
    }
    .kt-badge--username {
      height: 40px;
      width: 40px;
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  .kt-profile__main-info {
    margin: 8px 0 !important;
  }

  .kt-profile__main-info-name {
    display: flex;
    align-items: center;
    > span {
      margin-left: 8px;
    }
  }

  .kt-profile__main-info-position {
    > span {
      font-size: 1.2rem;
      color: #747194;
      font-weight: 400;
      &:nth-child(2) {
        opacity: 0.3;
        margin: 0 4px;
      }
    }
  }

  .kt-profile__contact {
    display: flex;
    flex-flow: row wrap !important;
    padding-right: 10px;
    padding-left: 10px;
    .kt-profile__contact-item {
      margin-right: auto;
      > span {
        &:nth-child(2) {
          padding-left: 6px !important;
          color: #747194 !important;
        }
      }
    }
    &.col {
      .kt-profile__contact-item {
        width: 100% !important;
      }
    }
    > div {
      margin: 8px 0;
      > span {
        &:nth-child(1) {
          min-width: 120px;
          display: block;
        }
        &:nth-child(2) {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }

  .kt-profile__content {
    padding-top: 18px;
    padding-bottom: 16px;
    .row {
      > div {
        order: 1;
        &:nth-child(2) {
          > div {
            border-bottom: none;
          }
        }
        &:nth-child(3) {
          order: 0;
          padding: 10px 0;
          border-bottom: 1px solid #ebedf2;
          width: 100%;
          margin: -16px 0 12px 0;
          a {
            display: flex;
            align-items: center;
            i {
              font-size: 16px;
              margin-right: 4px;
            }
          }
        }
        &:nth-child(4) {
          border-right: none;
          border-left: 1px solid #ebedf2;
          margin-left: auto;
          order: 4;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          flex: 1;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }
  .fwc-overdue {
    background-color: #F1F3FE;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center !important;
    margin: 0 10px;
    border-radius: 6px;
    min-width: 300px;
    max-width: 520px;
    margin-top: 16px;
    > span {
      &:nth-child(1) {max-width: 80px;}
      &:nth-child(2) {font-size: 32px; margin-left: 12px;}
    }
    &.active {
      background-color: #F4C7C7;
      color: #801818;
      &:hover {
        background-color: #f9bfbf;
      }
    }
  }
}

@media only screen and (min-width:1024px) {

  .fwc-detail-header {

    .kt-profile__content {
      padding-top: 12px;
      padding-bottom: 12px;
      .row {
        flex-flow: row wrap !important;
        > div {
          &:nth-child(1), &:nth-child(2) {
            width: fit-content !important;
            max-width: fit-content !important;
            align-items: center;
          }
          &:nth-child(2) {
            flex: 0 0 1;
            > div {
              border-right: none;
            }
          }
          &:nth-child(3) {
            padding: 12px 0;
            margin: -10px 0 12px 0;
          }
        }
      }
    }

    .col-auto-width {
      flex: 0 0 0 !important;
    }

    .kt-profile__main {
      padding-right: 24px;
      padding-bottom: 6px;
    }

    .kt-profile__contact, .person_amount_balance_total {
      border-right: none;
      width: fit-content;
      > a {
        &:nth-child(1) {
          margin-bottom: 6px !important;
        }
      }
    }

    .person_amount_balance_total {
      max-width: 400px;
      text-align: right;
    }

    .kt-profile__main-pic {
      margin-right: 12px !important;
    }

    .fwc-overdue {
      margin: 8px 0 8px auto !important;
    }

  }

}

#kt_aside_footer {
  margin: 0 26px 50px 26px;
  position: relative;
  > div {
    padding: 0 !important;
  }
  button {
    color: #fff !important;
    transition: none !important;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 40px;
    width: 100%;
    border: transparent;
    border: 1px solid #fff;
    border-radius: 3px !important;
    i {
      color: #fff !important;
      font-size: 14px !important;
      transition: none !important;
    }
    .button-text {
        margin-left: 6px;
    }
    &:hover {
      color: #415DE8 !important;
      background-color: #fff !important;
    }
  }
  &:hover {
    color: #415DE8 !important;
    button {
      color: #415DE8 !important;
      i {
        color: #415DE8 !important;
      }

    }
  }
  .kt-aside__footer-item.show {
    button {
      background-color: #fff !important;
      color: #415DE8 !important;
      i {
        color: #415DE8 !important;
      }
    }
  }
  .dropdown-menu {
    right: 0 !important;
    top: 14px !important;
    position: relative;
    .caret {
      transform: rotate(45deg);
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      margin: auto;
      width: 12px;
      height: 12px;
      background: #fff;
    }
  }
  .kt-nav__item {
    a {
      height: 46px;
      margin: 0 16px;
      border-radius: 3px;
      font-weight: 500;
      font-size: 13.5px;
    }
    img {
      margin-right: 10px;
    }
  }
}

.kt-aside--minimize #kt_aside_footer {
  margin-left: 18px !important;
  margin-right: 18px !important;
  button {
    display: flex !important;
    font-size: 0 !important;
    i {
      margin: 0 !important;
    }
  }
}

.fwc-rent-potential-calculator {
  > div:nth-child(1) {
    padding: 12px 0;
    margin: 0 24px 24px 24px;
    width: auto;
    border-bottom: 1px solid #ebedf2;
  }
  .kt-portlet__head {
    display: flex;
    flex-flow: column;
  }
  .kt-portlet__head-label {
    margin-bottom: 6px;
  }
  .kt-portlet__body {
    padding-top: 40px;
  }
  h2 {
    color: #000;
    font-size: 1.1rem;
  }
  .fwc-table {
    margin: 12px 0 40px 0;
    td {
      &:nth-child(1) {
        width: 50%;
      }
    }
  }
  #tab_rent_potential_details {
    margin-top: -16px;
  }
}

.fwc-table {
  width: 100%;
  tr{
    border-bottom: 1px solid #ebedf2;
    &.section-head {
      td {
        font-weight: 600;
      }
    }
    &.table-total {
      td {
        font-weight: 600;
      }
    }
  }
  td {
    color: #000;
    padding: 12px 12px 12px 0;
  }
}

@media only screen and (max-width:1024px) {
  .kt-header__topbar-wrapper {
    margin-bottom: 24px !important;
  }
}

.fwc-integration-coming-soon {
  background: transparent !important;
  color: #848ba5 !important;
  border: 1px solid #d2d5e0 !important;
}

.fwc-integration-logo-container {
  width: 80px !important;
  height: 80px !important;
  border: 1px solid #ebedf3 !important;
  border-radius: 3px !important;
  padding: 6px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: auto !important;
  }
}

.fwc-integration-title {
  margin-left: 16px !important;
}

.text-muted {
  margin-bottom: 0.5rem;
  color: #595d6e !important;

}

.spinner-padding:before {

  right: 2rem !important;

}

.gallery-list-text-overflow {
  list-style-position: inside;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-ul {
  padding-left: 0;
  margin: 0;
}

.fwc-table-body-color {
  color: #646c9a;
}

.center-text-vertically {
  margin: auto;
  width: 50%;
}
.distribution-key input {
  position: static;
  margin-left: 0;
  width: 100%;
  margin-bottom: 15px;

}
.distribution-key label {
  display: block;
  text-align: center;

}
.distribution-key .form-check {
  padding-left: 0;
}
.distribution-key--total {
  color: #999;
  width: 100%;
  display: block;
  text-align: center;
}
.position--contract-period {
  color: #999;
}
.fwc-overflow-x--scroll {
  overflow-x: scroll;
}

.fwc-card-border-selected {
  border: solid 1px #415DE8;
}

.rental-object-room {

  transition: none !important;
  background: #f7f8fa !important;

  .kt-grid-nav-v2__item-icon {
    i {
      color: #5d78ff !important;
    }
  }

  .mt-4 {
    i {
      color: #5d78ff;
    }
  }
}

.contract-collection-row {
  margin-bottom: 0.5rem;

  .collection-buttons {
    button {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }
}

.fwc-accounting-action-header-container {
  display: flex;
}

.fwc-space-left {
  margin-left: 1rem;
}

#fiscal-year-form {
  .form-group {
    margin-bottom: 0;
  }

  button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}

.register-logo-style {
  margin-bottom: 1rem;
}

.full-viewport-height {
  height: 100vh !important;
}

.tab_accounting_chart {
  max-width: 1380px;
  width: 100%;
  gap: 1rem;
  align-items: flex-end;
  padding: 10px 10px 0 10px;

  .input-date input {
      width: calc(10ch + 14px);
  }

  .input-filter-description {
      flex: 1;
  }

  & > div {
    margin-bottom: 10px;
  }
}

.fwc-account-journal {
  align-items: flex-end;
  & > div {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    & > div {
      width: 100% !important;
    }
  }

  .input-date {
    width: 100px;
  }

  .input-amount {
    width: 125px;
  }

  .input-receipt-number {
    width: 200px;
  }

  .input-amount-currency {
    width: 180px;
  }

  .input-description {
    width: 600px;
  }

  .input-filter-description {
    width: 250px;
  }

  @media (max-width: 768px) {
    & > div {
      width: 100% !important;
    }
  }

  & > div:not(:last-child) {
    margin-right: 10px;
  }
}

.modal-account-title {
  font-size: small;
  font-weight: bold;
  color: black;
}

.modal-account-label {
  font-size: small;
  color: black;
}

.modal-account-content {
  font-size: small;
  color: dimgray;
}

.img-grid-view {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.form-inline .form-group.full-width .bootstrap-select {
  width: 100%;
}

.table td.autofit {
  width: 1%;
  white-space: nowrap;
}

.ancillary-expense-smart-me {
  .header {
    color: gray;
    font-size: 14px;
    font-weight: bold;
  }
  .title {
    color: gray;
    font-size: 14px;
  }
  .form-group {
    margin: 0!important;
  }
  .smart-me-symbol {
    width: 50px;
    img {
      width: 18px;
    }
  }
}

.flex-vertical-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.portfolio-content-head-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.fwc-no-padding {
  padding: 0 !important;
}

.fwc-no-margin {
  margin: 0 !important;
}

.fwc-no-box-shadow {
  box-shadow: none !important;
}

.fwc-full-width {
  width: 100% !important;
}

.fwc-arrow-icon-animate {
  transform: rotate(0deg);
  transition: transform 0.25s linear;
}

.fwc-arrow-icon-animate.fwc-open {
  transform: rotate(90deg);
  transition: transform 0.25s linear;
}

.fwc-font-size-header {
  font-size: 1.2rem;
}

.fwc-font-size-header-small {
  font-size: 1rem;
  color: #888eb2;
}

.fwc-font-size-content {
  font-size: 1.25rem;
}

.fwc-font-size-content-small {
  font-size: 1rem;
}

.form-check-inline .form-check {
  display: inline-block;
  margin-left: 0;
  padding-left: 0;
  margin-right: 0.5rem;
}

.simple-collection div[class*='collection-actions'] {
  display: none;
}

.loading {
  width: 100%;
  height: 70px;
  margin: 15px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: black;
  margin: 0 5px;

  &.first {
    animation: slide 2s ease-in-out infinite;
    animation-delay: .1s;
  }

  &.second {
    animation: slide 2s ease-in-out infinite;
    animation-delay: .5s;
  }

  &.third {
    animation: slide 2s ease-in-out infinite;
    animation-delay: 1s;
  }
}

@keyframes slide {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(0);
  }
}

.pdf-document-preview {
  height: 85vh;
  width: 100%;
}

.image-preview {
  height: 80vh;
  max-width: 100%;
  object-fit: contain;
}

.fwc-view-size {
  max-height: 90vh;
  max-width: 90vw;
}

.fwc-overflow-y {
  overflow-y: auto;
  max-height: 100%;
}

.fwc-responsive-img {
  max-width: 100%;
  max-height: 100%;
}

.dropdown-menu div[role="listbox"] {
  max-height: 385px !important;
}

.dropdown.disabled {
  border: 1px solid #a1a8c3 !important;
}

.split-payment-icon {
  background: url('../images/icons/split-payment-icon.png');
  background-size: 16px;
  width: 16px;
  height: 16px;
}

#total-payment-split {
  font-size: large;
}

#total-payment-split {
  font-size: large;
  margin-bottom: 0.5rem;
}

@media (max-width: 1380px) {
  .recipient-bank-account-group .dropdown-menu {
    width: 500px;
    min-width: 500px !important;
  }
}

@media (max-width: 1250px) {
  .recipient-bank-account-group .dropdown-menu {
    width: 450px;
    min-width: 450px !important;
  }
}

@media (max-width: 1024px) {
  .recipient-bank-account-group .dropdown-menu {
    width: 350px;
    min-width: 350px !important;
  }
}

@media (max-width: 576px) {
  .recipient-bank-account-group .dropdown-menu {
    width: 200px;
    min-width: 200px !important;
  }
}

@media (min-width: 1024px) {
  .modal-lg, .modal-xl {
    max-width: 90% !important;
  }
}

@media (min-width: 576px) {
  .modal-lg, .modal-xl {
    max-width: 90% !important;
  }
}

.dropdown-debitor-invoice .dropdown-menu {
    left: -140px !important;
    top: 40px !important;
}
