//
// Badge
//



.kt-badge {
	padding: 0;
	margin: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	font-size: 0.8rem;

	// Sizing
	&.kt-badge--md {
		height: 24px;
		width: 24px;
		font-size: 0.9rem;
	}

	&.kt-badge--lg {
		height: 30px;
		width: 30px;
		font-size: 1rem;
	}

	&.kt-badge--xl {
		height: 40px;
		width: 40px;
		font-size: 1.1rem;
		font-weight: bold;
	}

	// Font weight
	&.kt-badge--bold {
		font-weight: 500;
	}

	&.kt-badge--bolder {
		font-weight: 600;
	}

	&.kt-badge--boldest {
		font-weight: 700;
	}

	// State colors
	@each $name, $color in $kt-state-colors {
	    &.kt-badge--#{$name} {
	    	color: kt-get($color, inverse);
			background: kt-get($color, base);
	    }

	    &.kt-badge--outline.kt-badge--#{$name} {
	    	background: transparent;
	    	color: kt-get($color, base);
			border: 1px solid kt-get($color, base);

			&.kt-badge--outline-2x {
				border: 2px solid kt-get($color, base);
			}
	    }
	}

	// State colors
	@each $name, $color in $kt-state-colors {
	    &.kt-badge--unified-#{$name} {
	    	color: kt-get($color, base);
			background: rgba(kt-get($color, base), 0.1);
	    }
	}

	// Inline mode
	&.kt-badge--inline {
		height: 0;
		width: auto;
		padding: 0.75rem 0.75rem;

		@include kt-rounded {
			border-radius: 2px;
		}

		// Sizing
		&.kt-badge--md {
			padding: 0.8rem 0.6rem;
		}

		&.kt-badge--lg {
			padding: 0.9rem 0.7rem;
		}

		&.kt-badge--xl {
			padding: 1rem 0.8rem;
		}
	}

	// Pill mode
	&.kt-badge--pill {
		border-radius: 2rem;
	}

	// Dot style
	&.kt-badge--dot {
	    display: inline-block;
		line-height: 4px;
		min-height: 4px;
    	min-width: 4px;
    	height: 4px;
    	width: 4px;
    	font-size: 0;
    	vertical-align: middle;
    	text-align: center;

		&.kt-badge--md {
			line-height: 6px;
			min-height: 6px;
    		min-width: 6px;
			height: 6px;
			width: 6px;
		}

		&.kt-badge--lg {
			line-height: 8px;
			min-height: 8px;
    		min-width: 8px;
			height: 8px;
			width: 8px;
		}

		&.kt-badge--xl {
			line-height: 10px;
			min-height: 10px;
    		min-width: 10px;
			height: 10px;
			width: 10px;
		}
	}

	// Square mode
	&.kt-badge--square {
		border-radius: 0;
	}

	// Elevated mode
	&.kt-badge--elevate {
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	}
}
